<template>
  <v-container>
    <v-layout fill-height>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col cols="12" md="6">
        <div v-if="this.$route.query.key && this.$route.query.insurance">
          <v-card v-if="!done  && !uploadLimitHit && !sessionOutdated">
            <v-card-title>
              Dokumentencheck
            </v-card-title>
            <v-card-text>
              Bitte klicken Sie auf das Dokumentensymbol und fügen Sie Ihre PDF Dokumente unten ein.
              <v-form
                  class="form"
                  ref="form"
                  v-model="valid"
              >
                <v-file-input
                    v-model="pictures"
                    label="Klicken Sie hier um ein Dokument hochzuladen"
                    :show-size="1000"
                    accept="application/pdf,image/*"
                    :color="$route.query.insurance==='MPU' ? 'blue' : 'secondary'"
                    :rules="pictureRules"
                    multiple
                    counter
                    required
                    prepend-icon="mdi-file-document"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 20"
                        :color="$route.query.insurance==='MPU' ? 'blue' : 'accent'"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 20"
                        class="overline grey--text text--darken-3 mx-2"
                    >
          +{{ files.length - 20 }} File(s)
        </span>
                  </template>
                </v-file-input>
              </v-form>
              <div class="mt-1">
                Bei technischen Problemen wenden Sie sich an:

                <v-btn v-if="$route.query.insurance==='MPU'"
                    :href="'mailto:mail@mpu-vorbereitungskurs-online.de?subject=Dokumentenupload&body=%0A%0A%0ADO%20NOT%20DELETE%0A%23%23%23%23key=' + $route.query.key + '======%23%23%23%23insurance=' + $route.query.insurance + '%23%23%23%23%0ANICHT%20L%C3%96SCHEN'"
                    color="blue">
                  mail@mpu-vorbereitungskurs-online.de
                </v-btn>
                <v-btn v-else
                       :href="'mailto:info@dokumentenanwalt.de?subject=Dokumentenupload&body=%0A%0A%0ADO%20NOT%20DELETE%0A%23%23%23%23key=' + $route.query.key + '======%23%23%23%23insurance=' + $route.query.insurance + '%23%23%23%23%0ANICHT%20L%C3%96SCHEN'"
                       color="secondary">
                  info@dokumentenanwalt.de
                </v-btn>
              </div>


            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'" @click="upload()" :disabled="blockButtonWhileRequestAndForm()">Hochladen</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="done">
            <v-card-title>
              Die Dokumente wurden erfolgreich übermittelt
            </v-card-title>
            <v-card-text>
              Wir werden die Dokumente bis zum nächstes Werktag bearbeiten.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="hidden-sm-and-down">
                <v-btn :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"  @click="() => { this.done = false }" class="mr-2">Weiteres Dokument Hochladen</v-btn>
                <v-btn :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"  href="https://stag-legal.de/">Über uns</v-btn>
              </div>
              <div class="hidden-md-and-up">
                <v-btn :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"  @click="() => { this.done = false }">Weiteres Dokument Hochladen</v-btn>
              </div>
            </v-card-actions>
            <v-card-actions class="hidden-md-and-up">
              <v-spacer></v-spacer>
              <v-btn :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"  href="https://stag-legal.de/">Über uns</v-btn>
            </v-card-actions>
          </v-card>
          <v-card color="error" v-if="uploadLimitHit">
            <v-card-title>
              Die maximale Anzahl an Dokumenten ist erreicht.
            </v-card-title>
            <v-card-text>
              Sie haben 20/20 Dokumente hochgeladen.
            </v-card-text>
          </v-card>
          <v-card color="error" v-if="sessionOutdated">
            <v-card-title>
              Der Link ist nicht mehr gültig!
            </v-card-title>
            <v-card-text>
              Ihr Fall wurde bereits geschlossen.
            </v-card-text>
          </v-card>
        </div>
        <error-card v-if="!this.$route.query.key || !this.$route.query.insurance"/>
      </v-col>
    </v-layout>
    <DatenweitergabeDialog/>
  </v-container>
</template>

<script>
import {uploadLegalRequest} from "@/utils/requestHandler";
import {showAlert} from "@/utils/alertHandler";
import {PdfService} from "@/utils/imgToPdf";
import DatenweitergabeDialog from "@/components/upload/documents/DatenweitergabeDialog";
import ErrorCard from "@/components/upload/documents/ErrorCard";

export default {
  name: "LegalUpload",
  components: {ErrorCard, DatenweitergabeDialog},
  props: {},
  data: () => ({
    pictures: [],
    valid: false,
    done: false,
    sessionOutdated: false,
    uploadLimitHit: false,
    isLoading: false,
  }),
  methods: {
    blockButtonWhileRequestAndForm() {
      if (this.valid === false) {
        return true
      }
      return this.isLoading === true;
    },
    async upload() {
      this.isLoading = true;
      let formData = [];
      const documents = this.pictures.filter(img => img.type.split("/")[0] !== "image");
      const pictures = this.pictures.filter(img => img.type.split("/")[0] === "image");

      documents.forEach(doc => {
        let temp = new FormData();
        const newFile = new File([doc], encodeURIComponent(doc.name), {
          type: doc.type,
        });
        temp.append("file", newFile);
        formData.push(temp);
      });

      if (pictures.length > 0) {
        const service = new PdfService();
        const pdf = await service.generatePdf("GeneratedPDF", pictures);
        pdf.getBlob(blob => {
          let temp = new FormData();
          temp.append("file", blob, "ConvertedImages.pdf");
          formData.push(temp);

          this.uploadFiles(formData);
        });
      } else {
        this.uploadFiles(formData);
      }
    },
    uploadFiles(formData) {
      formData.forEach((fD, index) => {
        uploadLegalRequest(this.ownDecode(this.$route.query.key), fD, this.$route.query.insurance).then(() => {
          if (index === formData.length - 1) {
            this.pictures = [];
            this.done = true;
            showAlert("Vielen Dank", "success");
            this.isLoading = false;
          }
        }).catch(error => {
          if (error && error.response) {
            if (429 === error.response.status) {
              this.uploadLimitHit = true;
            }
            if (412 === error.response.status) {
              this.sessionOutdated = true;
            }
          }
        });
      });
    },
    ownDecode(url) {
      let temp = encodeURI(url);
      temp = temp.replaceAll(":", "%3A");
      temp = temp.replaceAll("€", "%E2%82%AC");
      temp = temp.replaceAll("/", "%2F");
      temp = temp.replaceAll("?", "%3F");
      temp = temp.replaceAll("#", "%23");
      temp = temp.replaceAll("[", "%5B");
      temp = temp.replaceAll("]", "%5D");
      temp = temp.replaceAll("@", "%40");
      temp = temp.replaceAll("$", "%24");
      temp = temp.replaceAll("&", "%26");
      temp = temp.replaceAll("+", "%2B");
      temp = temp.replaceAll(",", "%2C");
      temp = temp.replaceAll(";", "%3B");
      temp = temp.replaceAll("=", "%3D");
      return temp
    },
  },
  computed: {
    LegalUpload() {
      return this.$t("LegalUpload");
    },
    pictureRules() {
      return [
        files => !files || !files.some(file => file.size > 100_663_296) || 'Eine Datei darf nicht größer als 100mb sein.',
        v => v.length !== 0 || "Bitte fügen Sie Ihre Dokumente hinzu."
      ]
    }
  }
};

</script>

<style scoped>

</style>
